// Copyright 2022-2023 @Polkasafe/polkaSafe-ui authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

export default (address: string, shortenAddressLength: number = 4) => {
	if (!address || address.length < 8) {
		return address;
	}

	return `${address.substring(0, shortenAddressLength)}...${address.substring(address.length - shortenAddressLength)}`;
};
